import React, { useEffect, useState } from 'react';
import { Space, Card, Button, Typography, Divider, Tag, Row, Col, Upload, message, Badge, Image } from 'antd';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { InboxOutlined, DeleteOutlined } from '@ant-design/icons';
import Parse from 'parse';
import { upload } from '@testing-library/user-event/dist/upload';
const { Title, Text } = Typography;
const { Dragger } = Upload;

const InterpretationImages = ({study, showUpload, setInterpretation}) => {
    const [fileList, setFileList] = useState([]);
    const [images, setImages] = useState([]); 

    const uploadImage = async (b64, study) => {
        const Images = Parse.Object.extend('Images');
        const image = new Images();
        const studyPointer = {
            __type: 'Pointer',
            className: 'Studies',
            objectId: study?.objectId
        }
        image.set('study', studyPointer);
        image.set('file', b64);
        const response = await image.save();
        console.log(response)
        if (response) {
            message.success('Imagen subida exitosamente');
        }
    }
    useEffect(() => {
        const loadImages = async () => {
            const Images = Parse.Object.extend('Images');
            const query = new Parse.Query(Images);
            query.equalTo('study', {
                __type: 'Pointer',
                className: 'Studies',
                objectId: study?.objectId
            });
            const images = await query.find();
            const imagesArray = images.map((img) => img.get('file'));
            console.log(imagesArray)
            setImages(imagesArray);
            imagesArray.length > 0 && setInterpretation((prev) => {
                return {
                    ...prev,
                    images: imagesArray
                }
            })
        }
        loadImages();
    }, [study])

    return (
        <Space direction='vertical' style={{padding: 20, width: '80vw'}} >
            <Card title="Imágenes del estudio" style={{ width: '100%' }}>
            <Space direction='horizontal' style={{padding: 20}}>
                    {images.map((img, i) => 
                        showUpload ? <Badge count={<DeleteOutlined style={{ color: 'red' }} />} onClick={() => {
                            const Images = Parse.Object.extend('Images');
                            const query = new Parse.Query(Images);
                            query.equalTo('file', img);
                            query.first().then((image) => {
                                image.destroy().then((response) => {
                                    console.log(response)
                                    if (response) {
                                        message.success('Imagen eliminada exitosamente');
                                    }
                                })
                            })
                            setImages((prev) => {
                                return prev.filter((_, index) => index !== i)
                            })
                        }}>
                             <Image key={i} src={img} style={{width: 100, height: 100}} />
                        </Badge>
                        : <Image key={i} src={img} style={{width: 100, height: 100}} />
                    )}
                </Space>
                {showUpload && <Dragger
                 showUploadList={false}
                 accept="image/*"
                 fileList={fileList}
                 multiple={true}
                 action={(file) => {
                     // Encode to base64
                     const reader = new FileReader(); 
                     reader.onload = (e) => {
                            const b64 = e.target.result;
                            setImages((prev) => {
                                return [...prev, b64];
                            });
                            console.log(b64)
                            uploadImage(b64, study);
                     };
                     reader.readAsDataURL(file)
                     // console.log('B64', b64)
                     // setB64(b64)
                     // return false
                 }} >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text"> Arrastra o busca una imagen para agregarla</p>
                   
                </Dragger>}
               
            </Card>
        </Space>
    )
}

export default InterpretationImages